import React from "react";
import logo from "../assets/images/favicon.ico";

function TopAppBar(){

function onLoad(){
  //const button = document.querySelector('#menu-button'); // Hamburger Icon
  const menu = document.querySelector('#menu'); // Menu

  //button.addEventListener('click', () => {
    menu.classList.toggle('hidden');
  //});
}
    return (
      <nav className="flex flex-wrap items-center justify-between w-full py-4 md:py-0 px-4 
      text-lg text-gray-700  navbar-sticky navbar-glass">
          <div className="flex">
              <a className="btn btn-circle bg-gradient-to-r from-indigo-200 to-purple-800" href="/"><img src={logo} width={30} height={30} alt="logo"></img></a>
          {/*<a className="text-lg font-bold text-red-500 p-2" href="/">Arfusoft</a>*/}
          </div>
        <svg width="28px" height="28px" onClick={onLoad} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" 
        className="cursor-pointer md:hidden block dark:text-white">
                        <g id="Menu / Menu_Alt_05">
                            <path id="Vector" d="M12 17H19M5 12H19M5 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>
       
       <div className="hidden w-full md:flex md:items-center md:w-auto" id="menu">
          <ul
            className="
              pt-4
              text-base text-gray-700
              md:flex
              md:justify-between 
              md:pt-0">

            <li>
              <a className="text-black-400 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/">Home</a>
            </li>
            <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/applications">Applications</a>
            </li>
            <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/portfolio">Domains</a>
            </li>                        
            <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/policy">Policy</a>
            </li>
            <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/contact">Contact</a>
            </li>            
            <li>
              <a className="text-black-500 dark:text-white md:p-4 py-2 block hover:text-purple-700 " href="/about">About</a>
            </li>
          </ul>
        </div>
    </nav>
       

  
    );
  }


  export default  TopAppBar;