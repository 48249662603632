import {React, useState, useEffect} from "react";

import shap from "../../assets/images/services-shape.svg";
import shap1 from "../../assets/images/services-shape-1.svg";
import shap2 from "../../assets/images/services-shape-2.svg";
import shap3 from "../../assets/images/services-shape-3.svg";

import about1 from "../../assets/images/about1.svg";
import aboutshap1 from "../../assets/images/about-shape-1.svg";
import about2 from "../../assets/images/about2.svg";
import aboutshap2 from "../../assets/images/about-shape-2.svg";
import about3 from "../../assets/images/about3.svg";

import business from "../../assets/images/business.png";




function  WorkFlows(){
    return (
<>
<section id="services" className="services-area pt-120">
    <div className="container">
        <div className="justify-center row">
            <div className="w-full lg:w-2/3">
                <div className="pb-10 text-center section-title">
                    <div className="m-auto line"></div>
                    <h2 className="title text-purple-500">Well-engineered software solutions  
                        <span className="text-gray-500 dark:text-gray-400"> that permit to maximize your profits.</span></h2>
                </div> {/* section title */}
            </div>
        </div> {/* row */}
        <div className="justify-center row">
            <div className="w-full sm:w-2/3 lg:w-1/3">
                <div className="single-services wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
                    <div className="services-icon">
                        <img className="shape" src={shap} alt="shape"/>
                        <img className="shape-1" src={shap1} alt="shape"/>
                        <i className="lni lni-baloon"></i>
                    </div>
                    <div className="mt-8 services-content">
                        <h3 className="mb-8 text-xl font-bold text-sky-400">Secure</h3>
                        <p className="mb-8 text-gray-600 text-lg dark:text-gray-400">The recent surge in cyberattacks taking advantage of software vulnerabilities leads us to put security at the heart of any software solution.
                            We identify and respond to vulnerabilities early in the development process when it costs less and is more effective.
                        </p>
                        <br/> <br/> 
                    </div>
                </div> {/* single services */}
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3">
                <div className="mt-8 text-center single-services wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="services-icon">
                        <img className="shape" src={shap} alt="shape"/>
                        <img className="shape-1" src={shap2} alt="shape"/>
                        <i className="lni lni-cog"></i>
                    </div>
                    <div className="mt-8 services-content">
                        <h3 className="mb-8 text-xl font-bold text-sky-400">Fast</h3>
                        <p className="mb-8 text-gray-600 text-lg dark:text-gray-400">
                            Speed and quality are undoubtedly the top goals any software development team aspires to. Speed can help you build an advantage over slower competitors while keeping your customer base engaged.
                            This is why we always do our best to deliver quality products on time. <br/> <br/><br/> 
                        </p>
                        
                    </div>
                </div> {/* single services */}
            </div>
            <div className="w-full sm:w-2/3 lg:w-1/3">
                <div className="mt-8 text-center single-services wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                    <div className="services-icon">
                        <img className="shape" src={shap} alt="shape"/>
                        <img className="shape-1" src={shap3} alt="shape"/>
                        <i className="lni lni-bolt-alt"></i>
                    </div>
                    <div className="mt-8 services-content">
                        <h3 className="mb-8 text-xl font-bold text-sky-400">Higher quality </h3>
                        <p className="mb-8 text-gray-600 text-lg dark:text-gray-400">
                            Some digital products deal with sensitive information or handle delicate tasks that can't afford to fail.
                            The software in charge of powering those industries needs to boast a superb performance, a strong security level, and robust backup and remediation systems. 
                            We prioritize quality to give end-users the best experience with our software.
                        </p>
                        
                    </div>
                </div> {/* single services */}
            </div>
        </div> {/* row */}
    </div> {/* container */}
</section>



<section id="mission" className="relative pt-20 about-area">
    <div className="container">
        <div className="row">
            <div className="w-full lg:w-1/2">
                <div className="mx-4 mt-12 about-content wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="mb-4 section-title">
                        <div className="line"></div>
                        <h3 className="title text-purple-500"><span className="text-gray-500 dark:text-white">Software solution</span> tailored to your business needs </h3>
                    </div> {/* section title */}
                    <p className="mb-8 text-gray-600 text-lg dark:text-gray-400">
                        Digital transformation is now a standard of growth for every company. 
                        From startups to major companies, we help businesses reach their goals by developing the required software. 
                        
                        
                    </p>
                    
                </div> {/* about content */}
            </div>
            <div className="w-full lg:w-1/2">
                <div className="mx-4 mt-12 text-center about-image wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <img src={about1} alt="about"/>
                </div> {/* about image */}
            </div>
        </div> {/* row */}
    </div> {/* container */}
    <div className="about-shape-1">
        <img src={aboutshap1} alt="shape"/>
    </div>
</section>



<section className="relative pt-20 about-area">
    <div className="about-shape-2">
        <img src={aboutshap2} alt="shape"/>
    </div>
    <div className="container">
        <div className="row">
            <div className="w-full lg:w-1/2 lg:order-last">
                <div className="mx-4 mt-12 about-content wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="mb-4 section-title">
                        <div className="line"></div>
                        <h3 className="title text-purple-500">Modern and cutting-edge<span className="text-gray-600 dark:text-white" > technologies</span></h3>
                    </div> {/* section title */}
                    <p className="mb-8 text-gray-600 text-lg dark:text-gray-400">
                        Technology is growing exponentially. Computers' speed and power have generally been doubling every one and a half to two years since the 1960s and 70s.
                        New and modern programming languages like <strong>Golang, Rust and Kotlin </strong> take advantage of the computer's full power through concurrency support.
                        Arfusoft combines new technologies with established, battle-tested languages such as <strong>C++ and C# </strong> to deliver performant and high-quality software.
                    </p>
                    
                </div> {/* about content */}
            </div>
            <div className="w-full lg:w-1/2 lg:order-first">
                <div className="mx-4 mt-12 text-center about-image wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <img src={about2} alt="about"/>
                </div> {/* about image */}
            </div>
        </div> {/* row */}
    </div> {/* container */}
</section>




<section className="relative pt-20 about-area">
    <div className="container">
        <div className="row">
            <div className="w-full lg:w-1/2">
                <div className="mx-4 mt-12 about-content wow fadeInLeftBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="mb-4 section-title">
                        <div className="line"></div>
                        <h3 className="title text-purple-500"> Cross-platform <span className="text-gray-600 dark:text-white" >applications</span></h3>
                    </div> {/* section title */}
                    <p className="mb-8  text-gray-600 text-lg dark:text-gray-400">
                        Cross-platform app development is a good choice when you need multi-platform functionality.
                        With cross-platform development, we develop a single app that can work on multiple platforms such as Android, IOS, Desktop, and Web. 
                        For a business, cross-platform development permits to save much money. Arfusoft will use technologies like <strong>React and Flutter </strong> to deliver cross-platform software solutions tailored to your business needs.
                        
                    </p>
                    
                </div> {/* about content */}
            </div>
            <div className="w-full lg:w-1/2">
                <div className="mx-4 mt-12 text-center about-image wow fadeInRightBig" data-wow-duration="1s" data-wow-delay="0.5s">
                    <img src={about3} alt="about"/>
                </div> {/* about image */}
            </div>
        </div> {/* row */}
    </div> {/* container */}
    <div className="about-shape-1">
        <img src={aboutshap1} alt="shape"/>
    </div>
</section>



<section id="vision" className="pt-20 video-counter">
    <div className="container">
        <div className="row">
            <div className="w-full lg:w-1/2">
                <div className="relative pb-8 mt-12 video-content wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">
                    <div className="relative mr-6 rounded-lg ">
                        <div className="video-image">
                            <img src={business} alt="video"/>
                        </div>

                    </div> {/* video wrapper */}
                </div> {/* video content */}
            </div>
            <div className="w-full lg:w-1/2">
                <div className="p-2 mt-12 counter-wrapper lg:pl-16 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.8s">
                    <div className="counter-content">
                        <div className="mx-4 mb-8 section-title">
                            <div className="line"></div>
                            <h3 className="title text-purple-500">Every business <span className="text-gray-600 dark:text-white" > should have a website.</span></h3>
                        </div> {/* section title */}
                        <p className="text text-gray-600 text-lg dark:text-gray-400">
                            Creating a website gives you space to share your business, establish your role in the industry, and connect with the customers who need you. Another reason you should have a website for your business is to increase your organization's credibility. Several providers are offering a similar service to your customers. One way to stand out is by having a website that looks good and communicates quality information to your consumers.

                        </p>
                    </div> {/* counter content */}
                    <div className="row no-gutters">
                        <div className="flex items-center justify-center single-counter counter-color-1">
                            <div className="text-center counter-items">
                                <span className="text-2xl font-bold text-gray-500"><span className="counter"></span></span>
                                <p className="text-white">Credibility</p>
                            </div>
                        </div> {/* single counter */}
                        <div className="flex items-center justify-center single-counter counter-color-2">
                            <div className="text-center counter-items">
                                <span className="text-2xl font-bold text-gray-500"><span className="counter"></span></span>
                                <p className="text-white">Marketing</p>
                            </div>
                        </div> {/* single counter */}
                        <div className="flex items-center justify-center single-counter counter-color-3">
                            <div className="text-center counter-items">
                                <span className="text-2xl font-bold text-gray-500"><span className="counter"></span></span>
                                <p className="text-white">Profit</p>
                            </div>
                        </div> {/* single counter */}
                    </div> {/* row */}
                </div> {/* counter wrapper */}
            </div>
        </div> {/* row */}
    </div> {/* container */}
</section>
</>

    )
}

export default WorkFlows;