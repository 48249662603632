import { BrowserRouter , Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/Home";
import Applications from "./components/Applications";
import {PortfolioAll, PortfolioCom, PortfolioNet, PortfolioOrg, PortfolioOthers} from "./components/Domains";

import Term from "./components/Term";
import Policy from "./components/Policy";
import NotFound from "./components/NotFound";
import About from "./components/About";
import Contact from "./components/Contact";



const App = () => {
  return (
    <BrowserRouter>
      <Routes>
          <Route path = "/" element = { <Layout></Layout> }>
            <Route index element = { <Home></Home> }></Route>

            <Route path = "/applications" element = { <Applications/> } ></Route>
            <Route path='/portfolio' element={<PortfolioAll />}></Route>
            <Route path='/portfolio/com' element={<PortfolioCom />}></Route>
            <Route path='/portfolio/net' element={<PortfolioNet />}></Route>
            <Route path='/portfolio/org' element={<PortfolioOrg />}></Route>
            <Route path='/portfolio/others' element={<PortfolioOthers />}></Route>

            <Route path = "/policy" element = { <Policy/> } ></Route>
            <Route path = "/terms" element = { <Term/> } ></Route>
            <Route path = "/contact" element = { <Contact/> } ></Route>
            <Route path = "/about" element = { <About></About> } ></Route>
            <Route path='*' element={<NotFound />}></Route>
          </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App

