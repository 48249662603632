import Hero from "./landing/Hero";
import Cta from "./landing/Cta";
import WorkFlows from "./landing/WorkFlows";
import Testimonials from "./landing/Testimonials";
import Footer from "./landing/Footer";
import CtaEnd from "./landing/CtaEnd";
import {Helmet} from "react-helmet";


function Home () {
    return(
        <>

    <Helmet>
        <meta charSet="utf-8" />
        <title>Arfusoft Home page</title>
        <meta name="description" content="Software Solutions and brandable domain names by Arfusoft." />              
        <meta name="keywords" content="arfusoft,software,domains,buy domain names,develop software,mobile applications,desktop applications,backend,frontend,react,kotlin,go"/>
        <meta property="og:title" content="Arfusoft Home page" />        
        <meta property="og:description" content="Software Solutions and brandable domain names by Arfusoft." />        
        <meta property="og:site_name" content="Arfusoft Home page" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
    </Helmet>


      <Hero/>
 {/*====== PRELOADER PART START ======*/}

 <div className="hidden preloader">
    <div className="loader">
        <div className="ytp-spinner">
            <div className="ytp-spinner-container">
                <div className="ytp-spinner-rotator">
                    <div className="ytp-spinner-left">
                        <div className="ytp-spinner-circle"></div>
                    </div>
                    <div className="ytp-spinner-right">
                        <div className="ytp-spinner-circle"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<Cta/>
<WorkFlows/>
<CtaEnd/>
<a href="#" className="back-to-top"><i className="lni lni-chevron-up"></i></a>
< div className="py-24"></div>

        </>
  )
}
export default Home;