import React, {useState} from "react";
import librarian from "../../assets/images/tech4r.png";






export default function Hero (){
    return (
        <section className="text-gray-600 body-font bg-gradient-to-r from-indigo-900 to-purple-800 ">
  <div className="container mx-auto flex px-4 py-24 md:flex-row flex-col items-center">
    <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
      
    <div
        className="animate-[gradient_6s_linear_infinite]  pb-5 font-nacelle text-3xl font-semibold text-transparent md:text-4xl"
        data-aos="fade-up">
                <span className="text-white">We can develop the perfect application for your business.</span>
    </div>
    <p className="text-xl text-gray-300 dark:text-gray-400 mb-8" data-aos="fade-up" data-aos-delay="200">
     
      </p>
    </div>
    <div className="lg:max-w-lg lg:w-full md:w-1/2 w-full">
      <img className="object-cover object-center rounded h-full" alt="hero" src={librarian}/>
    </div>
  </div>
</section>
    )
}